<template>
  <div class="infinite-scroll-wrapper" ref="infiniteScrollWrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    total: {
      type: Number,
      default: () => null,
    },
    limit: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      page: 1,
      hasReached: false,
    }
  },
  methods: {
    handleScroll() {
      const target = this.$refs.infiniteScrollWrapper;
      const rect = target.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.bottom <= windowHeight && !this.hasReached && this.total > this.page && !this.isLoading) {
        this.page++
        this.$emit('fetch-data', { page: this.page, limit: this.limit })
        this.hasReached = true;
      } else if (rect.bottom > windowHeight) {
        this.hasReached = false;
      }
      this.hasReached = false;
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }
};
</script>